import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledCompareOverview = styled(motion.div)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndexes[3],
    width: '100%',
    padding: `${theme.spaces[7]} 0`,
    marginTop: `${theme.spaces[8]}`,

    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100vw',
        backgroundColor: theme.colors.white,
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.05)',
        zIndex: '-1',
        // marginLeft: `calc(-5vw - 33px)`,

        // [breakpoints.xl]: {
        // marginLeft: `calc(-50vw + ${theme.sizes.contentMaxWidth / 2 - 8}px)`,
        // },
    },
}));

export const StyledCompareOverviewWrapper = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr auto',
    gap: theme.spaces[3],
}));

export const StyledCompareActions = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces[6],
    padding: theme.spaces[3],
}));

export const StyledCompareProductCards = styled.div(({ theme }) => ({
    display: 'flex',
    minHeight: '123px',
    gap: theme.spaces[3],
    backgroundColor: theme.colors.grey05,
    borderRadius: '5px',
}));

export const StyledCompareProductButton = styled.button(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: `${theme.spaces[3]}`,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    textAlign: 'left',
}));

export const StyledCompareProductImage = styled.div(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    height: '100%',
    minWidth: '100px',
    marginRight: `${theme.spaces[2]}`,
}));

export const StyledCompareProductClearButton = styled.button(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    alignSelf: 'flex-start',
    padding: `${theme.spaces[2]}`,
    margin: `${theme.spaces[1]}`,
    border: 0,
    backgroundColor: 'transparent',
}));

export const StyledCEmptyCards = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spaces[1],
    paddingBottom: theme.spaces[2],
    opacity: '0.5',
}));
