import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { center, cover } from '~/shared/utils/styled';
import { breakpoints, theme } from '~/theme';
import { ifProp, switchProp } from 'styled-tools';
import { ModalButtonPosition } from './Modal';

export const StyledModal = styled.div({
    ...cover,
    ...center,
    position: 'fixed',
    zIndex: theme.zIndexes[3],
    top: '0',
    left: '0',
    bottom: 'auto',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: `60px ${theme.spaces[5]} ${theme.spaces[5]}`,

    [breakpoints.md]: {
        padding: `80px ${theme.spaces[7]} ${theme.spaces[7]}`,
    },
});

export const StyledModalContent = styled.div<{ autoSize: boolean; transparent?: boolean }>(
    () => ({
        backgroundColor: theme.colors.white,
        padding: theme.spaces[6],
        position: 'relative',
        width: '100%',
        height: '100%',
        flexGrow: 0,
        flexShrink: 1,
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        boxShadow: theme.elevations.md,
        borderRadius: 'clamp(2.4rem, 3.125vw, 4.5rem) clamp(0.8rem, 1.0416666666666665vw, 1.5rem)',

        [breakpoints.md]: {
            maxHeight: '100%',
            padding: theme.spaces[7],
        },
        [breakpoints.lg]: {
            padding: theme.spaces[8],
        },
    }),
    ifProp('autoSize', () => ({
        width: 'auto',
        height: 'auto',
    })),
    ifProp('transparent', () => ({
        backgroundColor: 'transparent',
        boxShadow: 'none',
    })),
);

export const StyledCloseButton = styled.button<{ position: ModalButtonPosition }>(
    {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        border: 'none',
        cursor: 'pointer',
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: 'white',
        zIndex: 1000,
        top: 10,

        [breakpoints.md]: {
            top: 30,
            width: 50,
            height: 50,
        },
    },
    switchProp('position', {
        left: ({ theme }) => ({
            left: theme.spaces[5],
            [breakpoints.md]: {
                left: theme.spaces[7],
            },
        }),
        center: () => ({
            left: '50%',
            transform: 'translateX(-50%)',
        }),
        right: ({ theme }) => ({
            right: theme.spaces[5],
            [breakpoints.md]: {
                right: theme.spaces[7],
            },
        }),
    }),
);

export const StyledBackdrop = styled.div({
    position: 'fixed',
    inset: '0 0 0 0',
    background: theme.colors.black,
    opacity: 0.5,
    zIndex: 0,
    cursor: 'pointer',
});

export const StyledModalWrapper = styled(motion.div)({
    zIndex: theme.zIndexes.max,
    position: 'relative',
});
