import { useLocation } from 'react-use';
import { useFrame } from '~/shared/utils';

export const useIsSearchPage = () => {
    const { data: frameData } = useFrame();
    const searchUrl = frameData?.staticLinks?.searchPage?.url;
    const location = useLocation();
    const isSearchPage = location.pathname == searchUrl;
    return {
        searchUrl,
        isSearchPage,
    };
};
