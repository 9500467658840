import React, { useCallback, useMemo } from 'react';
import { Button } from '~/shared/components';
import { FiltersDrawer } from '../FiltersDrawer';
import Filter from '$icons/filter.svg';
import {
    StyledDropdownFiltersBar,
    FilterButtons,
    StyledProductCategories,
    StyledFilterButton,
} from './styled';
import { useProductsStore } from '../../hooks';
import { ProductCategoryList } from '../ProductCategoryList';
import { useTranslation } from '~/shared/utils/translation';
import { M140ProductListModule, TranslationKey } from '~/lib/data-contract';
import { Facet } from '../../../model';
import { EventBus } from '~/shared/utils/event-bus/event-bus';
import { useModule } from '~/templates/blocks/hooks/useModule';
export type DropdownFiltersBarProps = {
    showUserFilters?: boolean;
    showCategoryOverview?: boolean;
    showEmptyCategories?: boolean;
};

export const DropdownFiltersBar = ({
    showUserFilters = true,
    showCategoryOverview = true,
    showEmptyCategories,
}: DropdownFiltersBarProps) => {
    const {
        facets,
        getSelectedFilterCount,
        setShowFiltersDrawer,
        facetButtons,
        hiddenFacets,
        activeCategories,
    } = useProductsStore();
    const { translate } = useTranslation();
    const { element: m140Module } = useModule<M140ProductListModule>();
    const validWebGeneralTags = m140Module?.webGeneralTagsOptions ?? [];
    const onFacetButtonClick = (facet: Facet) => {
        setShowFiltersDrawer(true);
        // This fixes event bus firing before the drawer is open
        setTimeout(() => {
            EventBus.emit('AccordionOpen', `FacetAccordion_${facet.attribute}`);
            EventBus.emit('AccordionScrollTo', `FacetAccordion_${facet.attribute}`);
        }, 0);
    };

    const getFacetButtonLabel = useCallback(
        (facet: Facet, selectCount = 0) => {
            if (facet.key === 'WebGeneralTags' && m140Module?.webGeneralTagsHeadline) {
                return <>{m140Module?.webGeneralTagsHeadline}</>;
            }
            return (
                <>
                    {translate(
                        `Kompan.Commerce.Filters${facet.label}` as TranslationKey,
                        facet.label,
                    )}
                    {selectCount > 0 && ` (${selectCount})`}
                </>
            );
        },
        [translate],
    );

    const shouldFacetBeIncluded = useCallback(
        (facet: Facet) =>
            facetButtons.includes(facet.attribute) && !hiddenFacets.includes(facet.attribute),
        [facetButtons, hiddenFacets],
    );

    const sanitizedFacets = useMemo(() => {
        return facets
            ?.filter((facet) => {
                if (facet.displayType === 'MULTI_CHECKBOX_WEB_GENERAL_TAGS' && 'items' in facet) {
                    return (
                        facet.items?.some((item) =>
                            validWebGeneralTags.includes(`${item.value}`),
                        ) && shouldFacetBeIncluded(facet)
                    );
                }
                return shouldFacetBeIncluded(facet);
            })
            ?.sort((a, b) => {
                return facetButtons.indexOf(a.attribute) - facetButtons.indexOf(b.attribute);
            });
    }, [facets, validWebGeneralTags, facetButtons]);

    return (
        <StyledDropdownFiltersBar>
            {showCategoryOverview && (
                <StyledProductCategories>
                    <ProductCategoryList
                        scopedCategories={activeCategories}
                        showEmptyCategories={showEmptyCategories}
                    />
                </StyledProductCategories>
            )}
            {showUserFilters && (
                <FilterButtons>
                    {sanitizedFacets.map((facet) => {
                        const selectCount = getSelectedFilterCount(facet);
                        return (
                            <StyledFilterButton
                                key={facet.label}
                                variant={selectCount > 0 ? 'Transparent' : 'Ghost'}
                                disableHoverAnimation={true}
                                type={'button'}
                                onClick={() => onFacetButtonClick(facet)}
                            >
                                {getFacetButtonLabel(facet, selectCount)}
                            </StyledFilterButton>
                        );
                    })}
                    <Button
                        variant="Ghost"
                        onClick={() => setShowFiltersDrawer(true)}
                        disableHoverAnimation
                        icon={<Filter />}
                    >
                        {translate('Kompan.Commerce.ManyMoreButton')}
                    </Button>
                    <FiltersDrawer />
                </FilterButtons>
            )}
        </StyledDropdownFiltersBar>
    );
};
