import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledCompareOverlay = styled.div<{ numberOfProducts: number }>(
    () => ({
        maxWidth: '1000px',
    }),
    ifProp({ numberOfProducts: 2 }, () => ({
        maxWidth: '750px',
    })),
);

export const StyledCompareOverlayHeader = styled.div<{ numberOfProducts: number }>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1.2fr 1fr 1fr 1fr',
        // gap: theme.spaces[6],
        paddingBottom: theme.spaces[5],
    }),
    ifProp({ numberOfProducts: 2 }, () => ({
        gridTemplateColumns: '1.2fr 1fr 1fr',
    })),
);

export const StyledCompareProductInfo = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
    textAlign: 'center',

    '> div': {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },

    '> div > span': {
        padding: `0 ${theme.spaces[3]}`,
    },
}));

export const StyledCompareOverlayContent = styled.ul<{ numberOfProducts: number }>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1.2fr 1fr 1fr 1fr',

        '> li > div': {
            borderTop: `1px solid ${theme.colors.grey20}`,
            textAlign: 'center',
            minHeight: '70px',
            display: 'flex',
            alignItems: 'center',
        },
    }),
    ifProp({ numberOfProducts: 2 }, () => ({
        gridTemplateColumns: '1.2fr 1fr 1fr',
    })),
);

export const StyledComparePListHeading = styled.div(({ theme }) => ({
    color: theme.colors.grey60,
    justifyContent: 'flex-start',
}));

export const StyledComparePListItem = styled.div(({ theme }) => ({
    justifyContent: 'center',
    flexFlow: 'column',
    padding: `0 ${theme.spaces[3]}`,
}));
