import styled from '@emotion/styled';
import { switchProp, ifProp } from 'styled-tools';
import { Button } from '~/shared/components';
import { breakpoints } from '~/theme';

export const StyledPaginationContainer = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    margin: `${theme.spaces[7]} 0 0`,
    flexDirection: 'column',
    [breakpoints.md]: {
        margin: `${theme.spaces[9]} 0 0`,
    },
}));

export const StyledPaginationLine = styled.div<{ alignment: 'left' | 'center' | 'right' }>(
    ({ theme }) => ({
        width: '100%',
        display: 'flex',
        marginBottom: theme.spaces[4],
    }),
    switchProp('alignment', {
        left: () => ({
            justifyContent: 'flex-start',
        }),
        center: () => ({
            justifyContent: 'center',
        }),
        right: () => ({
            justifyContent: 'flex-end',
        }),
    })
);

export const StyledPaginationButton = styled(Button)(
    ({ theme }) => ({
        margin: `${theme.spaces[2]}`,
        width: 'auto',
        minWidth: '150px',
    }),
    ifProp('disabled', () => ({
        opacity: 0.5,
        pointerEvents: 'none',
    }))
);
