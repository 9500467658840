export const languageDictionary = {
    INT: 'English',
    AA: 'Afar',
    AB: 'Abkhazian',
    AE: 'Avestan',
    AF: 'Afrikaans',
    AK: 'Akan',
    AM: 'Amharic',
    AN: 'Aragonese',
    AR: 'Arabic',
    AS: 'Assamese',
    AV: 'Avaric',
    AY: 'Aymara',
    AZ: 'Azerbaijani',
    BA: 'Bashkir',
    BE: 'Belarusian',
    BG: 'Bulgarian',
    BH: 'Bihari languages',
    BI: 'Bislama',
    BM: 'Bambara',
    BN: 'Bengali',
    BO: 'Tibetan',
    BR: 'Breton',
    BS: 'Bosnian',
    CA: 'Catalan; Valencian',
    CE: 'Chechen',
    CH: 'Chamorro',
    CO: 'Corsican',
    CR: 'Cree',
    CS: 'Czech',
    CU: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    CV: 'Chuvash',
    CY: 'Welsh',
    DA: 'Danish',
    DE: 'German',
    DV: 'Divehi; Dhivehi; Maldivian',
    DZ: 'Dzongkha',
    EE: 'Ewe',
    EL: 'Greek, Modern (1453-)',
    EN: 'English',
    EO: 'Esperanto',
    ES: 'Spanish; Castilian',
    ET: 'Estonian',
    EU: 'Basque',
    FA: 'Persian',
    FF: 'Fulah',
    FI: 'Finnish',
    FJ: 'Fijian',
    FO: 'Faroese',
    FR: 'French',
    FY: 'Western Frisian',
    GA: 'Irish',
    GD: 'Gaelic; Scomttish Gaelic',
    GL: 'Galician',
    GN: 'Guarani',
    GU: 'Gujarati',
    GV: 'Manx',
    HA: 'Hausa',
    HE: 'Hebrew',
    HI: 'Hindi',
    HO: 'Hiri Motu',
    HR: 'Croatian',
    HT: 'Haitian; Haitian Creole',
    HU: 'Hungarian',
    HY: 'Armenian',
    HZ: 'Herero',
    IA: 'Interlingua (International Auxiliary Language Association)',
    ID: 'Indonesian',
    IE: 'Interlingue; Occidental',
    IG: 'Igbo',
    II: 'Sichuan Yi; Nuosu',
    IK: 'Inupiaq',
    IO: 'Ido',
    IS: 'Icelandic',
    IT: 'Italian',
    IU: 'Inuktitut',
    JA: 'Japanese',
    JV: 'Javanese',
    KA: 'Georgian',
    KG: 'Kongo',
    KI: 'Kikuyu; Gikuyu',
    KJ: 'Kuanyama; Kwanyama',
    KK: 'Kazakh',
    KL: 'Kalaallisut; Greenlandic',
    KM: 'Central Khmer',
    KN: 'Kannada',
    KO: 'Korean',
    KR: 'Kanuri',
    KS: 'Kashmiri',
    KU: 'Kurdish',
    KV: 'Komi',
    KW: 'Cornish',
    KY: 'Kirghiz; Kyrgyz',
    LA: 'Latin',
    LB: 'Luxembourgish; Letzeburgesch',
    LG: 'Ganda',
    LI: 'Limburgan; Limburger; Limburgish',
    LN: 'Lingala',
    LO: 'Lao',
    LT: 'Lithuanian',
    LU: 'Luba-Katanga',
    LV: 'Latvian',
    MG: 'Malagasy',
    MH: 'Marshallese',
    MI: 'Maori',
    MK: 'Macedonian',
    ML: 'Malayalam',
    MN: 'Mongolian',
    MR: 'Marathi',
    MS: 'Malay',
    MT: 'Maltese',
    MY: 'Burmese',
    NA: 'Nauru',
    NB: 'Bokmål, Norwegian; Norwegian Bokmål',
    ND: 'Ndebele, North; North Ndebele',
    NE: 'Nepali',
    NG: 'Ndonga',
    NL: 'Dutch; Flemish',
    NN: 'Norwegian Nynorsk; Nynorsk, Norwegian',
    NO: 'Norwegian',
    NR: 'Ndebele, South; South Ndebele',
    NV: 'Navajo; Navaho',
    NY: 'Chichewa; Chewa; Nyanja',
    OC: 'Occitan (post 1500)',
    OJ: 'Ojibwa',
    OM: 'Oromo',
    OR: 'Oriya',
    OS: 'Ossetian; Ossetic',
    PA: 'Panjabi; Punjabi',
    PI: 'Pali',
    PL: 'Polish',
    PS: 'Pushto; Pashto',
    PT: 'Portuguese',
    QU: 'Quechua',
    RM: 'Romansh',
    RN: 'Rundi',
    RO: 'Romanian; Moldavian; Moldovan',
    RU: 'Russian',
    RW: 'Kinyarwanda',
    SA: 'Sanskrit',
    SC: 'Sardinian',
    SD: 'Sindhi',
    SE: 'Northern Sami',
    SG: 'Sango',
    SI: 'Sinhala; Sinhalese',
    SK: 'Slovak',
    SL: 'Slovenian',
    SM: 'Samoan',
    SN: 'Shona',
    SO: 'Somali',
    SQ: 'Albanian',
    SR: 'Serbian',
    SS: 'Swati',
    ST: 'Sotho, Southern',
    SU: 'Sundanese',
    SV: 'Swedish',
    SW: 'Swahili',
    TA: 'Tamil',
    TE: 'Telugu',
    TG: 'Tajik',
    TH: 'Thai',
    TI: 'Tigrinya',
    TK: 'Turkmen',
    TL: 'Tagalog',
    TN: 'Tswana',
    TO: 'Tonga (Tonga Islands)',
    TR: 'Turkish',
    TS: 'Tsonga',
    TT: 'Tatar',
    TW: 'Twi',
    TY: 'Tahitian',
    UG: 'Uighur; Uyghur',
    UK: 'Ukrainian',
    UR: 'Urdu',
    UZ: 'Uzbek',
    VE: 'Venda',
    VI: 'Vietnamese',
    VO: 'Volapük',
    WA: 'Walloon',
    WO: 'Wolof',
    XH: 'Xhosa',
    YI: 'Yiddish',
    YO: 'Yoruba',
    ZA: 'Zhuang; Chuang',
    ZH: 'Chinese',
    ZU: 'Zulu',
};

export const countryDictionary = {
    AD: { name: 'Andorra', language: languageDictionary.CA },
    AE: { name: 'United Arab Emirates', language: languageDictionary.AR },
    AL: { name: 'Albania', language: languageDictionary.SQ },
    AM: { name: 'Armenia', language: languageDictionary.HY },
    AR: { name: 'Argentina', language: languageDictionary.ES },
    AT: { name: 'Austria', language: languageDictionary.DE },
    AU: { name: 'Australia', language: languageDictionary.EN },
    AZ: { name: 'Azerbaijan', language: languageDictionary.AZ },
    BA: { name: 'Bosnia and Herzegovina', language: languageDictionary.BS },
    BE: { name: 'Belgium', language: languageDictionary.NL },
    BG: { name: 'Bulgaria', language: languageDictionary.BG },
    BH: { name: 'Kingdom of Bahrain', language: languageDictionary.AR },
    BN: { name: 'Brunei Darussalam', language: languageDictionary.MS },
    BO: { name: 'Bolivia, Plurinational State of', language: languageDictionary.ES },
    BR: { name: 'Brazil', language: languageDictionary.PT },
    BS: { name: 'Bahamas', language: languageDictionary.EN },
    BW: { name: 'Botswana', language: languageDictionary.EN },
    BY: { name: 'Belarus', language: languageDictionary.BE },
    CA: { name: 'Canada', language: languageDictionary.EN },
    CD: { name: 'DR Congo', language: languageDictionary.FR },
    CG: { name: 'Congo', language: languageDictionary.FR },
    CH: { name: 'Switzerland', language: languageDictionary.DE },
    CI: { name: 'Ivory Coast', language: languageDictionary.FR },
    CL: { name: 'Chile', language: languageDictionary.ES },
    CM: { name: 'Cameroon', language: languageDictionary.EN },
    CN: { name: 'China', language: languageDictionary.ZH },
    CO: { name: 'Colombia', language: languageDictionary.ES },
    CR: { name: 'Costa Rica', language: languageDictionary.ES },
    CY: { name: 'Cyprus', language: languageDictionary.EL },
    CZ: { name: 'Czech Republic', language: languageDictionary.CS },
    DE: { name: 'Germany', language: languageDictionary.DE },
    DK: { name: 'Denmark', language: languageDictionary.DA },
    DM: { name: 'the Commonwealth of Dominica', language: languageDictionary.EN },
    DO: { name: 'Dominican Republic', language: languageDictionary.ES },
    DZ: { name: 'Algeria', language: languageDictionary.AR },
    EC: { name: 'Ecuador', language: languageDictionary.ES },
    EE: { name: 'Estonia', language: languageDictionary.ET },
    EG: { name: 'Egypt', language: languageDictionary.AR },
    ES: { name: 'Spain', language: languageDictionary.ES },
    ET: { name: 'Ethiopia', language: languageDictionary.AM },
    FI: { name: 'Finland', language: languageDictionary.FI },
    FJ: { name: 'Fiji Islands', language: languageDictionary.EN },
    FO: { name: 'Faroe Islands', language: languageDictionary.FO },
    FR: { name: 'France', language: languageDictionary.FR },
    GA: { name: 'Gabon', language: languageDictionary.FR },
    GB: { name: 'Great Britain', language: languageDictionary.EN },
    GE: { name: 'Georgia', language: languageDictionary.KA },
    GF: { name: 'French Guiana', language: languageDictionary.FR },
    GL: { name: 'Greenland', language: languageDictionary.KL },
    GN: { name: 'Guinea', language: languageDictionary.FR },
    GP: { name: 'Guadeloupe', language: languageDictionary.FR },
    GR: { name: 'Greece', language: languageDictionary.EL },
    GT: { name: 'Guatemala', language: languageDictionary.ES },
    HK: { name: 'Hong Kong', language: languageDictionary.EN },
    HN: { name: 'Honduras', language: languageDictionary.ES },
    HR: { name: 'Croatia', language: languageDictionary.HR },
    HU: { name: 'Hungary', language: languageDictionary.HU },
    ID: { name: 'Indonesia', language: languageDictionary.ID },
    IE: { name: 'Ireland', language: languageDictionary.EN },
    IL: { name: 'Israel', language: languageDictionary.HE },
    IN: { name: 'India', language: languageDictionary.HI },
    IQ: { name: 'Iraq', language: languageDictionary.AR },
    IR: { name: 'Iran', language: languageDictionary.FA },
    IS: { name: 'Iceland', language: languageDictionary.IS },
    IT: { name: 'Italy', language: languageDictionary.IT },
    JO: { name: 'Jordan', language: languageDictionary.AR },
    JP: { name: 'Japan', language: languageDictionary.JA },
    KE: { name: 'Kenya', language: languageDictionary.SW },
    KH: { name: 'Cambodia', language: languageDictionary.KM },
    KR: { name: 'Korea, Republic of', language: languageDictionary.KO },
    KW: { name: 'Kuwait', language: languageDictionary.AR },
    KZ: { name: 'Kazakhstan', language: languageDictionary.KK },
    LB: { name: 'Lebanon', language: languageDictionary.AR },
    LI: { name: 'Liechtenstein', language: languageDictionary.DE },
    LK: { name: 'Sri Lanka', language: languageDictionary.SI },
    LT: { name: 'Lithuania', language: languageDictionary.LT },
    LU: { name: 'Luxembourg', language: languageDictionary.LB },
    LV: { name: 'Latvia', language: languageDictionary.LV },
    LY: { name: 'Libya', language: languageDictionary.AR },
    MA: { name: 'Morocco', language: languageDictionary.AR },
    MC: { name: 'Monaco', language: languageDictionary.FR },
    MD: { name: 'Moldova, Republic of', language: languageDictionary.RO },
    ME: { name: 'Montenegro', language: languageDictionary.SR },
    MG: { name: 'Madagascar', language: languageDictionary.MG },
    MK: { name: 'Macedonia', language: languageDictionary.MK },
    MN: { name: 'Mongolia', language: languageDictionary.MN },
    MQ: { name: 'Martinique', language: languageDictionary.FR },
    MR: { name: 'Mauritania', language: languageDictionary.AR },
    MT: { name: 'Malta', language: languageDictionary.MT },
    MU: { name: 'Mauritius', language: languageDictionary.EN },
    MV: { name: 'Maldives', language: languageDictionary.DV },
    MX: { name: 'Mexico', language: languageDictionary.ES },
    MY: { name: 'Malaysia', language: languageDictionary.MS },
    MZ: { name: 'Mozambique', language: languageDictionary.PT },
    NG: { name: 'Nigeria', language: languageDictionary.EN },
    NI: { name: 'Nicaragua', language: languageDictionary.ES },
    NL: { name: 'Netherlands', language: languageDictionary.NL },
    NO: { name: 'Norway', language: languageDictionary.NB },
    NZ: { name: 'New Zealand', language: languageDictionary.EN },
    OM: { name: 'Oman', language: languageDictionary.AR },
    PA: { name: 'Panama', language: languageDictionary.ES },
    PE: { name: 'Peru', language: languageDictionary.ES },
    PG: { name: 'Papua New Guinea', language: languageDictionary.EN },
    PH: { name: 'Philippines', language: languageDictionary.EN },
    PK: { name: 'Pakistan', language: languageDictionary.UR },
    PL: { name: 'Poland', language: languageDictionary.PL },
    PM: { name: 'Saint Pierre and Miquelon', language: languageDictionary.FR },
    PR: { name: 'Puerto Rico', language: languageDictionary.ES },
    PT: { name: 'Portugal', language: languageDictionary.PT },
    PY: { name: 'Paraguay', language: languageDictionary.ES },
    QA: { name: 'Qatar', language: languageDictionary.AR },
    RE: { name: 'Réunion', language: languageDictionary.FR },
    RO: { name: 'Romania', language: languageDictionary.RO },
    RS: { name: 'Serbia', language: languageDictionary.SR },
    RU: { name: 'Russia', language: languageDictionary.RU },
    RW: { name: 'Rwanda', language: languageDictionary.RW },
    SA: { name: 'Saudi Arabia', language: languageDictionary.AR },
    SB: { name: 'Solomon Islands', language: languageDictionary.EN },
    SC: { name: 'Seychelles', language: languageDictionary.EN },
    SE: { name: 'Sweden', language: languageDictionary.SV },
    SG: { name: 'Singapore', language: languageDictionary.EN },
    SI: { name: 'Slovenia', language: languageDictionary.SL },
    SK: { name: 'Slovakia', language: languageDictionary.SK },
    SM: { name: 'San Marino', language: languageDictionary.IT },
    SN: { name: 'Senegal', language: languageDictionary.FR },
    SO: { name: 'Somalia', language: languageDictionary.SO },
    SR: { name: 'Suriname', language: languageDictionary.NL },
    SX: { name: 'Sint Maarten', language: languageDictionary.NL },
    SZ: { name: 'Swaziland', language: languageDictionary.EN },
    TG: { name: 'Togo', language: languageDictionary.FR },
    TH: { name: 'Thailand', language: languageDictionary.TH },
    TM: { name: 'Turkmenistan', language: languageDictionary.TK },
    TN: { name: 'Tunisia', language: languageDictionary.AR },
    TR: { name: 'Turkey', language: languageDictionary.TR },
    TW: { name: 'Taiwan', language: languageDictionary.ZH },
    TZ: { name: 'Tanzania', language: languageDictionary.SW },
    UA: { name: 'Ukraine', language: languageDictionary.UK },
    UG: { name: 'Uganda', language: languageDictionary.EN },
    US: { name: 'United States', language: languageDictionary.EN },
    UY: { name: 'Uruguay', language: languageDictionary.ES },
    UZ: { name: 'Uzbekistan', language: languageDictionary.UZ },
    VE: { name: 'Venezuela', language: languageDictionary.ES },
    VG: { name: 'Virgin Islands', language: languageDictionary.EN },
    VN: { name: 'Viet Nam', language: languageDictionary.VI },
    VU: { name: 'Vanuatu', language: languageDictionary.BI },
    WS: { name: 'Samoa', language: languageDictionary.SM },
    XK: { name: 'Kosovo', language: languageDictionary.SQ },
    ZA: { name: 'South Africa', language: languageDictionary.ZU },
    ZM: { name: 'Zambia', language: languageDictionary.EN },
};

export type countryCodeKey = keyof typeof countryDictionary;

export type languageCodeKey = keyof typeof languageDictionary;

export const getCountryName = (key: countryCodeKey) => {
    return countryDictionary[key]?.name;
};

export const getCountryList = () =>
    Object.entries(countryDictionary).map(([_, item]) => ({
        value: item.name,
    }));

export const getCountryCode = (name: string) =>
    Object.entries(countryDictionary).find(([_, item]) => item.name === name);
