import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
// import { motion } from 'framer-motion';

export const StyledFavoriteButton = styled.button<{ is_active: string }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        background: 'none',
        cursor: 'pointer',
        padEnd: 0,
        margin: 0,
        padding: 2,
        border: 'none',
        gap: theme.spaces[2],
        color: theme.colors.black100,
    }),
    ifProp({ is_active: 'true' }, () => ({
        'svg > path': {
            fill: 'currentColor',
        },
    })),
);

// export const StyledFavoriteIcon = styled(motion.div)(() => ({
//     display: 'flex',
//     flexGrow: 1,
//     height: '100%',
//     position: 'relative',

//     '> *': {
//         position: 'absolute',
//         top: 1,
//         left: 1,
//     },
// }));
