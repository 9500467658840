import { M140ProductListModule } from '~/lib/data-contract';

export const getActiveM140Categories = (
    productListConfig: M140ProductListModule['productListConfig'],
    productCategoryIds: M140ProductListModule['productCategoryIds'],
) => {
    if (productCategoryIds?.length) {
        return productCategoryIds;
    }
    if (productListConfig?.selectedCategory?.categoryId) {
        return [productListConfig.selectedCategory.categoryId];
    }

    return [];
};
