import React from 'react';

interface IUseObserver {
    threshold?: number | number[];
    root?: React.RefObject<Element> | null;
    rootMargin?: string;
}

export function useObserver({
    threshold = 0,
    root = null,
    rootMargin = '0px 0px 100px 0px',
}: IUseObserver) {
    const entryRef = React.useRef<HTMLDivElement | null>(null);
    const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                setEntry(entries[0]);
            },
            { threshold, root: root?.current ?? null, rootMargin },
        );

        if (entryRef?.current) observer.observe(entryRef?.current);

        return () => observer.disconnect();
    }, [entryRef]);

    return { entry, entryRef };
}
