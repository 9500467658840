import React, { PropsWithChildren, useEffect } from 'react';
import {
    StyledBackdrop,
    StyledCloseButton,
    StyledModal,
    StyledModalContent,
    StyledModalWrapper,
} from './styled';
import Close from '$icons/close-bold.svg';
import { useScrollLock } from '~/shared/hooks';
import { useKey } from 'react-use';
import { theme } from '$theme';
import { Icon, Portal } from '$shared/components';
import { AnimatePresence } from 'framer-motion';

export type ModalButtonPosition = 'left' | 'center' | 'right';

export type ModalProps = {
    onDismiss: () => void;
    onLoad?: () => void;
    transparent?: boolean;
    autoSize?: boolean;
    open?: boolean;
    closeButtonPosition?: ModalButtonPosition;
};

export const Modal = ({
    onDismiss,
    onLoad,
    transparent = false,
    autoSize = true,
    children,
    open = false,
    closeButtonPosition = 'right',
}: PropsWithChildren<ModalProps>) => {
    const { lock, unlock, isLocked } = useScrollLock();

    useKey('Escape', () => open && onDismiss());

    useEffect(() => {
        if (open && !isLocked) {
            lock();
            onLoad?.();
        } else if (!open && isLocked) {
            unlock();
        }
    }, [open]);

    return (
        <Portal>
            <AnimatePresence>
                {open && (
                    <StyledModalWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            type: theme.easings.smoothEase,
                        }}
                    >
                        <StyledModal>
                            <StyledBackdrop onClick={onDismiss} role="button" />
                            <StyledCloseButton onClick={onDismiss} position={closeButtonPosition}>
                                <Icon size={'md'}>
                                    <Close title={'Close'} />
                                </Icon>
                            </StyledCloseButton>
                            <StyledModalContent autoSize={autoSize} transparent={transparent}>
                                {children}
                            </StyledModalContent>
                        </StyledModal>
                    </StyledModalWrapper>
                )}
            </AnimatePresence>
        </Portal>
    );
};
