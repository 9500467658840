import { useMemo } from 'react';
import { Button, ColorDots, Image, Text } from '~/shared/components';
import {
    StyledCompareOverlay,
    StyledCompareOverlayContent,
    StyledCompareOverlayHeader,
    StyledComparePListHeading,
    StyledComparePListItem,
    StyledCompareProductInfo,
} from './Style';
import { Frame, useFrame, useTranslation } from '~/shared/utils';
import {
    getColumnTitleTranslation,
    getProductImage,
    getProductVariantColors,
    getValueTranslation,
} from '../../utils';
import { StyledListItemText } from '../../ProductListItem/styled';

import NextLink from 'next/link';
import { getCompareProductsLength, useCompareStore } from '../../hooks';
import {
    getDimensionsText,
    getFallHeightText,
    getSpaceRequired,
} from '../../utils/GetSpaceRequired';
import { usePage } from '~/templates/pages';
import { fallbackText } from '../../ProductListItem';
import { getPriceText } from '../../utils/getPriceText';
import { getCurrentMarketPath } from '~/templates/pages/utils';

export const CompareOverlay = () => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();

    const { market = 'int', culture = 'en' } = usePage();
    const locale = `${culture}-${market?.toUpperCase()}`;

    const { compareProducts, handleCloseOverlay } = useCompareStore();

    const compareProductsLength = getCompareProductsLength(compareProducts);

    const productPageUrl = `/${getCurrentMarketPath()}/p`;

    const showPrice = useMemo(
        () =>
            !!compareProducts.find(
                (item) => item?.data.ShowPriceOnKompan?.value !== 'False' || item?.listPrice,
            ),
        [compareProducts],
    );

    return (
        <StyledCompareOverlay numberOfProducts={compareProductsLength}>
            <StyledCompareOverlayHeader numberOfProducts={compareProductsLength}>
                <Text as={'h2'} variant={'display3'} style={{ gridColumn: 1 }}>
                    {translate('Kompan.Commerce.CompareOverlayTitle')}
                </Text>

                {compareProducts.map(
                    (product) =>
                        product !== null && (
                            <StyledCompareProductInfo key={`${product.productId}`}>
                                <div>
                                    <NextLink
                                        href={`${productPageUrl}/${product?.productId?.toLowerCase()}`}
                                        prefetch={false}
                                        legacyBehavior
                                    >
                                        <div
                                            css={{ maxWidth: '180px' }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Image
                                                height={180}
                                                width={180}
                                                objectFit="contain"
                                                layout="fixed"
                                                quality="70"
                                                content="objectFit"
                                                src={getProductImage(product)}
                                                alt={''}
                                                onClick={handleCloseOverlay}
                                            />
                                        </div>
                                    </NextLink>
                                    <span>
                                        <NextLink
                                            href={`${productPageUrl}/${product?.productId?.toLowerCase()}`}
                                            prefetch={false}
                                            legacyBehavior
                                        >
                                            <StyledListItemText
                                                variant={'body'}
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleCloseOverlay}
                                            >
                                                {product?.displayName}
                                            </StyledListItemText>
                                        </NextLink>
                                        <StyledListItemText variant="caption" colorVariant="grey">
                                            {product?.productId}
                                        </StyledListItemText>
                                    </span>
                                </div>

                                <NextLink
                                    href={`${productPageUrl}/${product?.productId?.toLowerCase()}`}
                                    prefetch={false}
                                    legacyBehavior
                                >
                                    <Button
                                        variant="Red"
                                        as="a"
                                        css={{ marginTop: '1rem' }}
                                        onClick={handleCloseOverlay}
                                    >
                                        {translate('Kompan.Commerce.CompareOverlayShowProduct')}
                                    </Button>
                                </NextLink>
                            </StyledCompareProductInfo>
                        ),
                )}
            </StyledCompareOverlayHeader>

            <StyledCompareOverlayContent
                numberOfProducts={compareProductsLength}
                role="table"
                aria-label="Product comparison"
            >
                <li role="rowgroup">
                    <StyledComparePListHeading role="row" style={{ gridColumn: 1 }}>
                        <Text variant={'caption'}>
                            {getColumnTitleTranslation(translate, 'Colors')}
                        </Text>
                    </StyledComparePListHeading>
                    <StyledComparePListHeading role="row" style={{ gridColumn: 1 }}>
                        <Text variant={'caption'}>
                            {getColumnTitleTranslation(translate, 'AgeGroup')}
                        </Text>
                    </StyledComparePListHeading>
                    <StyledComparePListHeading role="row" style={{ gridColumn: 1 }}>
                        <Text variant={'caption'}>
                            {getColumnTitleTranslation(translate, 'UserCapacity')}
                        </Text>
                    </StyledComparePListHeading>
                    <StyledComparePListHeading role="row" style={{ gridColumn: 1 }}>
                        <Text variant={'caption'}>
                            {getColumnTitleTranslation(translate, 'Space')}
                        </Text>
                    </StyledComparePListHeading>
                    <StyledComparePListHeading role="row" style={{ gridColumn: 1 }}>
                        <Text variant={'caption'}>
                            {getColumnTitleTranslation(translate, 'MaxFallHeight')}
                        </Text>
                    </StyledComparePListHeading>
                    {showPrice && (
                        <StyledComparePListHeading role="row" style={{ gridColumn: 1 }}>
                            <Text variant={'caption'}>
                                {getColumnTitleTranslation(translate, 'Price')}
                            </Text>
                        </StyledComparePListHeading>
                    )}
                </li>

                {compareProducts.map((product) => {
                    if (product) {
                        const priceText = getPriceText(product, frame as Frame, locale);

                        return (
                            <li role="rowgroup" key={`${product.productId}`}>
                                <StyledComparePListItem role="row">
                                    <ColorDots
                                        colors={getProductVariantColors(product)}
                                        maxColors={5}
                                    />
                                </StyledComparePListItem>
                                <StyledComparePListItem role="row">
                                    <StyledListItemText variant={'body'}>
                                        {product?.data?.AgeGroup?.value}
                                    </StyledListItemText>
                                </StyledComparePListItem>
                                <StyledComparePListItem role="row">
                                    <StyledListItemText variant={'body'}>
                                        {getValueTranslation(
                                            translate,
                                            'UserCapacity',
                                            `${product?.data?.UserCapacity?.value}`,
                                        )}
                                    </StyledListItemText>
                                </StyledComparePListItem>
                                <StyledComparePListItem role="row">
                                    <StyledListItemText variant={'body'}>
                                        {getSpaceRequired(product, locale) ?? fallbackText}
                                    </StyledListItemText>

                                    <StyledListItemText variant="caption" colorVariant="grey">
                                        {getDimensionsText(product)}
                                    </StyledListItemText>
                                </StyledComparePListItem>
                                <StyledComparePListItem role="row">
                                    <StyledListItemText variant={'body'}>
                                        {getFallHeightText(product, locale) ?? fallbackText}
                                    </StyledListItemText>
                                </StyledComparePListItem>
                                {showPrice && (
                                    <StyledComparePListItem role="row">
                                        <StyledListItemText variant={'body'}>
                                            {priceText
                                                ? getValueTranslation(
                                                      translate,
                                                      'Price',
                                                      `${getPriceText(
                                                          product,
                                                          frame as Frame,
                                                          locale,
                                                      )}`,
                                                  )
                                                : fallbackText}
                                        </StyledListItemText>
                                    </StyledComparePListItem>
                                )}
                            </li>
                        );
                    }

                    return '';
                })}
            </StyledCompareOverlayContent>
        </StyledCompareOverlay>
    );
};
