import React, { useEffect } from 'react';
import { LoadingIndicator } from '~/shared/components';
import { useObserver } from '~/shared/hooks';

export const LoadAllPagination = ({
    loadMore,
}: {
    loadMore: (e?: PointerEvent) => void | Promise<void>;
}) => {
    const { entry, entryRef } = useObserver({});

    useEffect(() => {
        entry?.isIntersecting && loadMore();
    }, [entry, entryRef]);

    return (
        <div ref={entryRef}>
            <LoadingIndicator />
        </div>
    );
};
