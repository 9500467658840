import { weakKey } from '~/shared/utils/jsx';
import { Notification, NotificationOptions } from './useNotificationModel';
import { useNotificationState } from './useNotificationState';

/**
 * Exposes functions and notifications
 * Use to update notification state
 */
export const useNotification = () => {
    const { notifications, add, remove } = useNotificationState();

    const push = (notification: NotificationOptions) => {
        // Configure default notification
        const configuredNotification: Notification = {
            text: 'Default text',
            severity: 'error',
            duration: 5000,
            id: weakKey(notification),
            ...notification,
        };

        add(configuredNotification);
    };
    const dismiss = (id: string) => remove(id);

    return { notifications, push, dismiss };
};
