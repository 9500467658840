import { useEffect, useState } from 'react';
import { StyledPaginationContainer, StyledPaginationLine, StyledPaginationButton } from './styled';
import { useProductsStore } from '../hooks';
import { useTranslation } from '~/shared/utils/translation';
import ArrowRight from '$icons/arrow-right.svg';
import ArrowLeft from '$icons/arrow-left.svg';
import { Icon } from '~/shared/components';
import { useLocation } from 'react-use';

export const SimplePagination = () => {
    const { setPagination, pagination, isSearching, isNoResults } = useProductsStore();
    const { translate } = useTranslation();
    const location = useLocation();
    const [nextButtonUrl, setNextButtonUrl] = useState('');
    const [prevButtonUrl, setPrevButtonUrl] = useState('');

    useEffect(() => {
        const rawPath = location.pathname;
        const nextParams = new URLSearchParams(location.search);
        nextParams.set('page', `${pagination.page + 2}`);
        setNextButtonUrl(`${rawPath}?${nextParams.toString()}`);

        const prevParams = new URLSearchParams(location.search);
        prevParams.set('page', `${pagination.page}`);
        if (pagination.page == 0) {
            prevParams.delete('page');
        }
        setPrevButtonUrl(`${rawPath}?${prevParams.toString()}`);
    }, [location.pathname, location.search, pagination.page]);

    const changePage = (direction: 'prev' | 'next') => {
        if (direction == 'next' && pagination.page < pagination.totalPages) {
            setPagination({
                ...pagination,
                page: pagination.page + 1,
            });
        } else if (direction == 'prev' && pagination.page > 0) {
            setPagination({
                ...pagination,
                page: pagination.page - 1,
            });
        }
    };

    return isNoResults() ? null : (
        <StyledPaginationContainer>
            <StyledPaginationLine alignment="center">
                <span>
                    {`${translate('Kompan.Generic.PageXofY')}`
                        .replace('[x]', `${pagination.page + 1}`)
                        .replace('[y]', `${pagination.totalPages}`)}
                </span>
            </StyledPaginationLine>
            <StyledPaginationLine alignment="center">
                <StyledPaginationButton
                    href={prevButtonUrl}
                    rel="prev"
                    disabled={isSearching || pagination.page === 0}
                    aria-disabled={isSearching || pagination.page === 0}
                    variant="Transparent"
                    icon={
                        <Icon>
                            <ArrowLeft />
                        </Icon>
                    }
                    iconAlignment="start"
                    onClick={(e) => {
                        e?.preventDefault();
                        changePage('prev');
                    }}
                    disableHoverAnimation={true}
                >
                    {translate('Kompan.Generic.Previous')}
                </StyledPaginationButton>
                <StyledPaginationButton
                    href={nextButtonUrl}
                    rel="next"
                    disabled={
                        isSearching ||
                        pagination.totalPages == 0 ||
                        pagination.page === pagination.totalPages - 1
                    }
                    aria-disabled={
                        isSearching ||
                        pagination.totalPages == 0 ||
                        pagination.page === pagination.totalPages - 1
                    }
                    variant="Transparent"
                    icon={
                        <Icon>
                            <ArrowRight />
                        </Icon>
                    }
                    iconAlignment="end"
                    onClick={(e) => {
                        e?.preventDefault();
                        changePage('next');
                    }}
                    disableHoverAnimation={true}
                >
                    {translate('Kompan.Generic.Next')}
                </StyledPaginationButton>
            </StyledPaginationLine>
        </StyledPaginationContainer>
    );
};
