import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { getFluidValue } from '~/theme/utils/getClamp';
import * as Popover from '@radix-ui/react-popover';

export const StyledProductOptions = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[2],
    position: 'relative',
    zIndex: 1,
}));

export const StyledOptionsFavorite = styled.div<{ isOpen: boolean; variant: string }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: getFluidValue(5),
        border: 'none',
        width: '32px',
        height: '32px',
        background: theme.colors.grey05,
        zIndex: 2,
    }),
    ifProp({ variant: 'transparent' }, () => ({
        background: 'transparent',
    })),
    ifProp({ isOpen: true }, ({ theme }) => ({
        backgroundColor: theme.colors.black100,
        color: theme.colors.white100,
        zIndex: -2,
    })),
);

export const StyledOptionsButtonTrigger = styled.button(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: getFluidValue(5),
    border: 'none',
    width: '32px',
    height: '32px',
    background: theme.colors.grey05,
    zIndex: 1,
}));

export const StyledOptionsButtonClose = styled.button(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: getFluidValue(5),
    border: 'none',
    width: '32px',
    height: '32px',
    background: theme.colors.black100,
    color: theme.colors.white100,
    zIndex: 1,
}));

export const StyledPopoverContent = styled(Popover.Content)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    marginLeft: '10px',
    borderRadius: getFluidValue(5),
    border: `1px solid ${theme.colors.black100}`,
    background: theme.colors.white100,
    zIndex: theme.zIndexes.portalLow,
    padding: `${theme.spaces[6]} ${theme.spaces[8]} ${theme.spaces[6]} ${theme.spaces[6]} `,
}));

export const StyledPopoverContentWrap = styled.ul(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: `${theme.spaces[5]} ${theme.spaces[3]} `,

    '> li > span': {
        width: '100%',
    },
}));

export const StyledPopoverClose = styled(Popover.Close)(() => ({
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
}));



export const StyledOptionsButton = styled.button<{ isOpen: boolean; variant: string }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: getFluidValue(5),
        border: 'none',
        width: '32px',
        height: '32px',
        background: theme.colors.grey05,
        zIndex: -1,
    }),
    ifProp({ variant: 'transparent' }, () => ({
        background: 'transparent',
    })),
    ifProp({ isOpen: true }, ({ theme }) => ({
        backgroundColor: theme.colors.black100,
        color: theme.colors.white100,
        zIndex: 2,
    })),
);

export const StyledOpenButton = styled.span({
    ' > figure': {
        transform: 'rotate(90deg)',
    },
});

export const StyledOptionsContent = styled.div<{ variant?: string }>(() => ({
    zIndex: '-1',
}));

export const StyledOptionsWrap = styled.ul<{ variant?: string }>(
    ({ theme }) => ({
        display: 'flex',
        flexFlow: 'column',
        gap: theme.spaces[2],
        position: 'absolute',
        overflow: 'hidden',
        minWidth: '200px',
        background: theme.colors.grey05,
        top: '0',
        right: '0',
        zIndex: -1,
        borderRadius: getFluidValue(5),
        padding: `${theme.spaces[4]} ${theme.spaces[7]} ${theme.spaces[4]} ${theme.spaces[5]}`,
        opacity: 0,

        ' > li': {
            filter: 'blur(0.5px)',
            opacity: 0,
        },
    }),
    ifProp({ variant: 'transparent' }, ({ theme }) => ({
        background: theme.colors.white100,
    })),
);
