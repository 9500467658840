import Heart from '$icons/heart.svg';
import { Text } from '~/shared/components';
import { Icon } from '../Icon';
import {
    StyledFavoriteButton,
    // StyledFavoriteIcon
} from './styled';

type FavoriteButton = {
    onClick: () => void;
    is_active: boolean;
    showText?: boolean;
    text?: string;
};

export const FavoriteButton = ({
    onClick,
    is_active = false,
    showText = false,
    text,
}: FavoriteButton) => {
    return (
        <StyledFavoriteButton onClick={onClick} is_active={is_active.toString()}>
            {/* <StyledFavoriteIcon
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.8 }}
                transition={{
                    type: 'spring',
                    stiffness: 400,
                    damping: 17,
                }}
            > */}
            <Icon size="md">
                <Heart />
            </Icon>
            {/* </StyledFavoriteIcon> */}

            {showText && <Text variant="captionSm">{text}</Text>}
        </StyledFavoriteButton>
    );
};
