import { ProductModelDto } from '~/lib/data-contract';

export const arrangeProductData = (product: ProductModelDto, locale: string) => ({
    productId: product.productReference,
    displayName: product.productName,
    listPrice: product.price,
    allVariants: product.colors?.map((color, idx) => ({
        data: {
            ImageCAD1: {
                value: product.variants?.[idx]?.imageCad1?.url,
            },
            ImageCAD2: {
                value: product.variants?.[idx]?.imageCad2?.url,
            },
            MainColor: {
                value: color.hexCode,
            },
            MainColorHexCode: {
                value: color.hexCode,
            },
        },
    })),
    data: {
        UserCapacity: { value: product.userCapacity },
        AgeGroup: { value: `${product.ageGroup?.text} ${product.ageGroup?.fromUnit}` },
        MaxFallHeightUnit: { value: product.maxFallHeight?.unit },
        [`MaxFallHeight_${locale}`]: {
            value: product.maxFallHeight?.value,
        },
        spaceMeasurementUnit: { value: product.safetyZoneArea?.unit },
        [`SafetyZoneArea_${locale}`]: {
            value: product.safetyZoneArea?.value,
        },
        ShowPriceOnKompan: {
            value: product.showPriceOnKompan ? 'True' : 'False',
        },
    },
});
