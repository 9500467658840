import { TranslationKey } from "~/lib/data-contract";
import { Facet, SelectedFacet } from "../../model";
import { RelewiseRangesType } from "../requests";
import { getFacetOptionTranslation } from "./getFacetOptionTranslation";
import { isSameFacetItem } from "./isSameFacetItem";

export const isSafetyZoneAreaFacet = (attribute: string) =>
    attribute.startsWith('SafetyZoneAreaWidth') || attribute.startsWith('SafetyZoneAreaLength');

export type dimensionsFilterType = { items: [{ value: RelewiseRangesType; label: string }]; attribute?: string };
type translate = (translation: TranslationKey, fallback?: string) => string;

type createFacetButton = {
    facet: SelectedFacet, correspondingFacet: Facet | undefined, dimensionsFilter: dimensionsFilterType | undefined, translate: translate
};

export const calculateSafetyZoneDimensions = (item: SelectedFacet, dimensionsFilter: dimensionsFilterType | undefined) => {
    const dimensionsValue = dimensionsFilter?.items[0].value;
    const itemValue = item.items[0].value as RelewiseRangesType;

    const lowerValue = dimensionsValue?.lowerBoundInclusive
        ? Math.min(dimensionsValue?.lowerBoundInclusive, itemValue.upperBoundExclusive! - 1)
        : itemValue.upperBoundExclusive! - 1;

    const upperValue = dimensionsValue?.upperBoundExclusive
        ? Math.max(dimensionsValue?.upperBoundExclusive, itemValue.upperBoundExclusive! - 1)
        : itemValue.upperBoundExclusive! - 1;

    dimensionsFilter = {
        attribute: 'SafetyZoneDimensions',
        items: [
            {
                label: `${lowerValue} - ${upperValue}`,
                value: {
                    lowerBoundInclusive: lowerValue,
                    upperBoundExclusive: upperValue,
                },
            },
        ],
    };

    return dimensionsFilter;
};

// to extract the locale from facets except AgeGroup_from
export const getCleanAttributeForArea = (attribute: string ) => {

    if (attribute.startsWith('AgeGroup_from')){
        return 'AgeGroup_from';
    }
    return attribute.split('_')[0];
};

export const createFacetButton = (facet: SelectedFacet, correspondingFacet: Facet | undefined, dimensionsFilter: dimensionsFilterType | undefined, translate: translate) => {


    return facet.items.map((item) => {
        const itemValue = item.value as RelewiseRangesType;
        const label =
            correspondingFacet?.displayType === undefined
                ? dimensionsFilter?.items[0].label || ''
                : correspondingFacet?.displayType === 'MULTI_RANGE'
                ? `${itemValue?.lowerBoundInclusive}${
                      itemValue?.upperBoundExclusive !== undefined &&
                      Number(itemValue?.upperBoundExclusive) > 0
                          ? ` - ${itemValue.upperBoundExclusive! - 1}`
                          : '+'
                  }`
                : correspondingFacet?.displayType === 'SPACE'
                ? item.label
                : correspondingFacet?.items?.find((facetItem) =>
                      isSameFacetItem(facetItem, item)
                  )?.label ?? '';

        return {
            attribute: facet.attribute,
            label: getFacetOptionTranslation(
                translate,
                getCleanAttributeForArea(facet.attribute),
                label,
                'Kompan.Commerce',
                correspondingFacet?.displayType
            ),
            value: item.value,
        };
    });
};