import { useEffect, useState } from 'react';
import { StyledPaginationContainer, StyledPaginationLine, StyledPaginationButton } from './styled';
import { useProductsStore } from '../hooks';
import { useTranslation } from '~/shared/utils/translation';
import ArrowDown from '$icons/arrow-down.svg';
import { useLocation } from 'react-use';
import { LoadAllPagination } from './LoadAllPagination';

export const ContinuousPagination = () => {
    const { setPagination, pagination, isSearching, isNoResults } = useProductsStore();
    const [paginationButtonUrl, setPaginationButtonUrl] = useState<string>('');
    const { translate } = useTranslation();
    const location = useLocation();
    const [loadAll, setLoadAll] = useState(false);

    useEffect(() => {
        const rawPath = location.pathname;
        const params = new URLSearchParams(location.search);

        setPaginationButtonUrl(`${rawPath}?${params.toString()}`);
    }, []);

    const loadMore = (e: PointerEvent) => {
        e?.preventDefault();
        if (pagination.page < pagination.totalPages) {
            const rawPath = location.pathname;
            const params = new URLSearchParams(location.search);

            setPaginationButtonUrl(`${rawPath}?${params.toString()}`);

            params.set('page', `${pagination.page + 2}`);
            setPagination({
                ...pagination,
                page: pagination.page + 1,
            });
        }
    };

    const handleLoadAll = () => {
        setLoadAll(true);
    };

    return isNoResults() ||
        pagination.totalPages == 0 ||
        pagination.page === pagination.totalPages - 1 ? null : (
        <StyledPaginationContainer>
            <StyledPaginationLine alignment="center">
                {!loadAll ? (
                    <>
                        <StyledPaginationButton
                            href={paginationButtonUrl}
                            disableHoverAnimation={true}
                            disabled={
                                isSearching ||
                                pagination.totalPages == 0 ||
                                pagination.page === pagination.totalPages - 1
                            }
                            variant="Red"
                            icon={<ArrowDown />}
                            iconAlignment="end"
                            onClick={loadMore as unknown as (e?: unknown) => void | Promise<void>}
                        >
                            {translate('Kompan.Commerce.LoadMore')}
                        </StyledPaginationButton>
                        <StyledPaginationButton
                            disableHoverAnimation={true}
                            disabled={
                                isSearching ||
                                pagination.totalPages == 0 ||
                                pagination.page === pagination.totalPages - 1
                            }
                            variant="Transparent"
                            icon={<ArrowDown />}
                            iconAlignment="end"
                            onClick={handleLoadAll}
                        >
                            {translate('Kompan.Commerce.LoadAll', 'Load all')}
                        </StyledPaginationButton>
                    </>
                ) : (
                    <LoadAllPagination
                        loadMore={loadMore as unknown as (e?: unknown) => void | Promise<void>}
                    />
                )}
            </StyledPaginationLine>
        </StyledPaginationContainer>
    );
};
