import dynamic from 'next/dynamic';
import { FormConversionSource } from '~/lib/data-contract';
import { Sidepanel, useTracking } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import { Product } from '../../model';
import { useMarket } from '~/shared/utils';
type ProductListQuoteFormProps = {
    activeProduct?: Product;
    show: boolean;
    onClose: () => void;
};

const M40ContactForm = dynamic(() => import('~/templates/blocks/components/M40ContactForm'));

export const ProductListQuoteForm = ({
    show,
    onClose,
    activeProduct,
}: ProductListQuoteFormProps) => {
    const { translate } = useTranslation();
    const { trackProductLead } = useTracking();
    const { culture } = useMarket();
    return (
        <Sidepanel culture={culture} open={show} onClose={onClose}>
            <M40ContactForm
                loadOnSidepanel
                spacingBottom="none"
                spacingTop="none"
                id={'productHero'}
                disableModuleAnimation
                pageElementIndex={0}
                type="m40ContactFormModule"
                headline={translate('Kompan.ProductDetail.GetQuote')}
                description={translate('Kompan.ProductDetail.GetQuoteSubHeader')}
                receiptHeadline={translate('Kompan.ProductDetail.GetQuoteReceiptHeadline')}
                receiptDescription={translate('Kompan.ProductDetail.GetQuoteReceiptDescription')}
                conversionSource={FormConversionSource.ProductGetQuote}
                topic={'Quote Request'}
                trackingEvent={(data) => {
                    trackProductLead({
                        product_name: activeProduct?.displayName,
                        product_id: activeProduct?.productId,
                        country: data.Address1_Country,
                        product_category:
                            activeProduct?.categoryPaths?.[0]?.pathFromRoot?.[0]?.displayName,
                        product_category2:
                            activeProduct?.categoryPaths?.[0]?.pathFromRoot?.[1]?.displayName,
                        product_category3:
                            activeProduct?.categoryPaths?.[0]?.pathFromRoot?.[2]?.displayName,
                    });
                }}
                onPanelClose={onClose}
                skipReceipt={false}
                disableTracking={true}
                injectMessage={`ProductRef: ${activeProduct?.productId}`}
            />
        </Sidepanel>
    );
};
