import { ConnectorProps } from '../../model';
import { useRelewiseProductsAPI } from '../requests';

/* Relewise Instant Search */
export const RelewiseConnector = ({
    sidebarFacet,
    useUrlState,
    predefinedFilters,
}: ConnectorProps) => {
    useRelewiseProductsAPI(
        sidebarFacet ? sidebarFacet[0] : undefined,
        useUrlState,
        predefinedFilters
    );

    return <></>;
};
