import { User } from '@relewise/client';
import { fetcher } from '~/lib/fetcher';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { PredictionSearchRequestResponseType } from '../requests';
export type getRelewiseSearchPredictionsParams = {
    term: string;
    take?: number;
    locale: string;
    user?: User;
};

export const getRelewiseSearchPredictions = async ({
    term,
    take = 5,
    locale,
    user,
}: getRelewiseSearchPredictionsParams) => {
    const {
        RELEWISE_API_URL,
        RELEWISE_ENVIRONMENT_ID,
        RELEWISE_SEARCH_API_KEY,
    } = publicRuntimeConfig();
    const trimmedTerm = term.trim();
    if (trimmedTerm == '') {
        return {
            predictions: [],
        };
    }
    const body = {
        $type: 'Relewise.Client.Requests.Search.SearchTermPredictionRequest, Relewise.Client',
        currency: {
            value: 'DKK',
        },
        language: {
            value: locale,
        },
        displayedAtLocation: 'search page',
        user: user,
        filters: null,
        postFilters: null,
        relevanceModifiers: null,
        term: trimmedTerm,
        take: take,
        settings: {
            $type:
                'Relewise.Client.Requests.Search.Settings.SearchTermPredictionSettings, Relewise.Client',
            targetEntityTypes: ['Product', 'Content'],
        },
    };

    const fetchData = () =>
        fetcher<PredictionSearchRequestResponseType>(
            `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/SearchTermPredictionRequest`,
            {},
            { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
            'POST',
            JSON.stringify(body)
        )
            .then((response) => response.json())
            .then((data) => {
                return data;
            });

    return fetchData();
};
