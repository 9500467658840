import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { motion } from 'framer-motion';
import { shouldNotForwardProps } from '~/shared/utils/styled';

export const StyledCompareButton = styled(motion.button, {
    shouldForwardProp: shouldNotForwardProps(['isPressed', 'showText']),
})<{ isPressed: boolean; showText: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        background: 'none',
        cursor: 'pointer',
        padEnd: 0,
        margin: 0,
        padding: 0,
        border: 'none',
        gap: theme.spaces[2],
        textAlign: 'left',

        '> figure': {
            margin: '0 2px 0 2px',
        },

        'svg > path': {
            fill: 'currentColor',
        },
    }),
    ifProp({ isPressed: true, showText: true }, ({ theme }) => ({
        color: theme.colors.red100,
    })),
);
