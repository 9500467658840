import styled from '@emotion/styled';
import { Button } from '~/shared/components';

export const StyledDropdownFiltersBar = styled.div(({ theme }) => ({
    width: '100%',
    'button figure': {
        color: theme.colors.red,
    },
}));

export const FilterButtons = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spaces[6],
    gap: theme.spaces[3],
}));

export const StyledProductCategories = styled.div(({ theme }) => ({
    marginBottom: theme.spaces[7],
}));

export const StyledFilterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.colors.grey05,
}));
