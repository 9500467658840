import { FavoriteButton } from '~/shared/components/FavoriteButton';
import { useFavoriteStorage } from '../hooks';
import { useIsClientSide } from '~/shared/hooks/useIsClientSide/useIsClientSide';
import { Button, useTracking } from '~/shared/components';
import { Product } from '../../model';
import { usePage } from '~/templates/pages';
import { countryCodeKey, getCountryName } from '~/lib/twoLetterIsoCodeCountryList';
import { useCallback, useMemo } from 'react';
import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';
import { useNotification } from '~/shared/hooks/useNotification';
import { weakKey } from '~/shared/utils/jsx';
import { useFrame, useTranslation } from '~/shared/utils';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

type Favorite = {
    product?: Product;
    productId?: string;
    productName?: string;
    showText?: boolean;
    text?: string;
    trackEventAdd?: () => void;
    trackEventRemove?: () => void;
};

export const Favorite = ({
    product,
    showText,
    text,
    trackEventAdd,
    trackEventRemove,
    productId,
    productName,
}: Favorite) => {
    const { favorites, toggleFavorite } = useFavoriteStorage();
    const isClientSide = useIsClientSide();
    const { trackFavoritesAddToFavorites, trackFavoritesRemoveFromFavorites } = useTracking();
    const { market = 'int' } = usePage();
    const { data: frame } = useFrame();
    const router = useRouter();

    const { push, dismiss } = useNotification();

    const { translate } = useTranslation();

    const isActive = useMemo(
        () => favorites?.[`${productId ?? product?.productId}`] || false,
        [favorites, productId, product],
    );

    const url =
        (frame?.staticLinks?.favoritePage?.url && `${frame?.staticLinks?.favoritePage?.url}`) || '';

    const notificationRemoved = {
        text: (
            <>
                {translate('Kompan.Favorites.ToastMessageRemoveText').replace(
                    '[x]',
                    `${productName ?? product?.displayName}`,
                )}
                <Button
                    variant={'Plain'}
                    size={'Inline'}
                    type={'button'}
                    onClick={() => {
                        handleAddToFavorites();
                        dismiss(weakKey(notificationRemoved));
                    }}
                >
                    {translate('Kompan.Favorites.ToastMessageRemoveButtonText')}
                </Button>
            </>
        ),
        severity: 'info' as Severity,
        duration: 5000,
    };

    const notificationAdded = {
        text: (
            <>
                {translate('Kompan.Favorites.ToastMessageAddText').replace(
                    '[x]',
                    `${productName ?? product?.displayName}`,
                )}
                <NextLink
                    aria-label={frame?.staticLinks?.favoritePage?.title}
                    href={router.asPath.includes('preview=true') ? `${url}?preview=true` : url}
                    prefetch={false}
                >
                    {translate('Kompan.Favorites.ToastMessageAddButtonText')}
                </NextLink>
            </>
        ),
        severity: 'info' as Severity,
        duration: 5000,
    };

    const handleRemoveFromFavorites = useCallback(() => {
        toggleFavorite(productId ?? product?.productId ?? '');
        if (trackEventRemove) {
            trackEventRemove();
        } else if (product) {
            trackFavoritesRemoveFromFavorites({
                country: (market && getCountryName(market as countryCodeKey)) || market,
                product_name: product.displayName,
                product_id: product.productId,
                product_category: product?.categoryPaths?.[0]?.pathFromRoot?.[0]?.displayName,
                product_category2: product?.categoryPaths?.[0]?.pathFromRoot?.[1]?.displayName,
                product_category3: product?.categoryPaths?.[0]?.pathFromRoot?.[2]?.displayName,
            });
        }
        push(notificationRemoved);
    }, [trackEventRemove, product, market, push]);

    const handleAddToFavorites = useCallback(() => {
        toggleFavorite(productId ?? product?.productId ?? '');
        if (trackEventAdd) {
            trackEventAdd();
        } else if (product) {
            trackFavoritesAddToFavorites({
                country: (market && getCountryName(market as countryCodeKey)) || market,
                product_name: product.displayName,
                product_id: product.productId,
                product_category: product?.categoryPaths?.[0]?.pathFromRoot?.[0]?.displayName,
                product_category2: product?.categoryPaths?.[0]?.pathFromRoot?.[1]?.displayName,
                product_category3: product?.categoryPaths?.[0]?.pathFromRoot?.[2]?.displayName,
            });
        }
    }, [trackEventAdd, product, market]);

    const handleOnClick = () => {
        if (isActive) {
            handleRemoveFromFavorites();
        } else {
            push(notificationAdded);
            handleAddToFavorites();
        }
    };

    return (
        <>
            {isClientSide && (
                <FavoriteButton
                    onClick={handleOnClick}
                    is_active={isActive}
                    showText={showText}
                    text={text}
                />
            )}
        </>
    );
};
