import { create } from 'zustand';
import { Notification } from './useNotificationModel';

export type NotificationState = {
    notifications: Notification[];
    add: (notification: Notification) => void;
    remove: (id: string) => void;
};

export const useNotificationState = create<NotificationState>((set) => ({
    notifications: [],
    add: (notification: Notification): void => {
        set((state) => ({
            ...state,
            notifications: [...state.notifications, notification],
        }));
    },
    remove: (id: string): void => {
        set((state) => ({
            ...state,
            notifications: state.notifications.filter(
                ({ id: notificationId }) => id !== notificationId,
            ),
        }));
    },
}));
