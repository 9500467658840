import {
    UseStructuredDataModel,
    StructuredDataOrganization,
    StructuredDataProduct,
    setJsonLd,
    StructuredDataListProduct,
    removeJsonLd,
} from '$shared/components';

const isServer = typeof window === 'undefined';

export const useStructuredData = (): UseStructuredDataModel => {
    const setOrganizationSchema = (organizationSchema: StructuredDataOrganization) => {
        if (isServer) return;

        organizationSchema['@context'] = 'https://schema.org';
        organizationSchema['@type'] = organizationSchema['@type'] || 'Organization';

        setJsonLd(document, organizationSchema);
    };

    const setProductSchema = (structuredDataProduct: StructuredDataProduct) => {
        if (isServer) return;

        structuredDataProduct['@context'] = 'https://schema.org';
        structuredDataProduct['@type'] = 'Product';
        structuredDataProduct['brand'] = {
            '@type': 'Brand',
            name: 'KOMPAN',
        };
        structuredDataProduct['aggregateRating']['@type'] = 'AggregateRating';
        structuredDataProduct['aggregateRating']['bestRating'] = '5';
        structuredDataProduct['aggregateRating']['worstRating'] = '1';
        structuredDataProduct['aggregateRating']['availability'] = 'https://schema.org/InStock';
        if (structuredDataProduct['aggregateRating']['availableAtOrFrom']) {
            structuredDataProduct['aggregateRating']['availableAtOrFrom']['@type'] = 'Country';
        }

        setJsonLd(document, structuredDataProduct);
    };

    const setListProductSchema = (StructuredDataListProduct: StructuredDataListProduct) => {
        if (isServer) return;

        StructuredDataListProduct['@context'] = 'https://schema.org';
        StructuredDataListProduct['@type'] = 'ItemList';

        setJsonLd(document, StructuredDataListProduct);
    };

    const removeListProductSchema = () => {
        if (isServer) return;

        removeJsonLd(document, 'ItemList');
    };

    return {
        setOrganizationSchema,
        setProductSchema,
        setListProductSchema,
        removeListProductSchema,
    };
};
