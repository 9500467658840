import CompareIcon from '$icons/compare.svg';
import { StyledCompareButton } from './styled';
import { Icon } from '../Icon';
import { Text } from '~/shared/components';
import Close from '$icons/close-bold.svg';

type CompareButton = {
    onClick: () => void;
    isPressed?: boolean;
    text?: string;
    showText?: boolean;
    iconSize?: 'md' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl';
};

export const CompareButton = ({
    onClick,
    isPressed = false,
    showText = true,
    text,
    iconSize = 'md',
}: CompareButton) => {
    return (
        <StyledCompareButton
            onClick={onClick}
            isPressed={isPressed}
            showText={showText}
            aria-pressed="true"
        >
            <>
                <Icon size={iconSize}>
                    {isPressed ? <Close title={'Close'} /> : <CompareIcon />}
                </Icon>
                {showText && (
                    <Text variant="captionSm" css={{ lineHeight: 1, display: 'contents' }}>
                        {text}
                    </Text>
                )}
            </>
        </StyledCompareButton>
    );
};
