import styled from '@emotion/styled';

export const StyledAlertContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',

    width: '640px',
    maxWidth: '100%',
    gap: theme.spaces[6],

    '> div': {
        textAlign: 'center',
    },

    '> div p': {
        color: theme.colors.grey60,
    },
}));
