import { AnimatePresence } from 'framer-motion';
import { StyledList } from '~/shared/components/Notifications/styled';
import { useNotification } from '~/shared/hooks/useNotification';
import { Notification } from './components/Notification';
import { useScrollDirection } from '~/shared/hooks/useScrollDirection';
import { useMedia } from 'react-use';

/**
 * Displays UI based on notification state
 * can be used in root or a scoped location
 *
 */
export const Notifications = () => {
    const { notifications, dismiss } = useNotification();
    const scrollDirection = useScrollDirection();

    const isMobile = useMedia('(max-width: 768px)', true);

    return (
        <StyledList layout scrollDirection={scrollDirection}>
            <AnimatePresence>
                {notifications.map((props) => (
                    <li key={props.id} style={{ width: '100%' }}>
                        <Notification
                            onDismiss={() => dismiss(props.id)}
                            {...props}
                            isMobile={isMobile}
                        />
                    </li>
                ))}
            </AnimatePresence>
        </StyledList>
    );
};
