import styled from '@emotion/styled';
import { Property } from 'csstype';
import { Text } from '$shared/components';
import { ifProp } from 'styled-tools';
import {
    productListTemplateColumns,
    productListTemplateColumnsWithPrice,
} from '../ProductListItem/styled';

export const StyledProductListHeadline = styled.li<{ showPrice: string }>(
    ({ theme }) => ({
        display: 'grid',
        gap: `0 ${theme.spaces[2]}`,
        paddingTop: theme.spaces[1],
        paddingBottom: theme.spaces[1],
        borderTop: `1px solid ${theme.colors.grey20}`,
        borderBottom: `1px solid ${theme.colors.grey20}`,
    }),
    ifProp({ showPrice: 'True' }, () => productListTemplateColumnsWithPrice),
    ifProp({ showPrice: 'False' }, () => productListTemplateColumns),
);

export const StyledHeadlineColumn = styled.div<{
    justifyContent?: Property.JustifyContent;
    alignItems?: Property.AlignItems;
    textAlign?: Property.TextAlign;
    flexDirection?: Property.FlexDirection;
}>(
    ({
        justifyContent = 'center',
        textAlign = 'center',
        flexDirection = 'row',
        alignItems = 'center',
        theme,
    }) => ({
        display: 'inline-flex',
        alignItems: alignItems,
        justifyContent: justifyContent,
        textAlign: textAlign,
        flexDirection: flexDirection,

        paddingRight: `${theme.spaces[4]}`,

        color: theme.colors.grey60,
    }),
);

export const StyledHeadlineText = styled(Text)<{
    colorVariant?: 'black' | 'grey';
}>(
    ({ theme }) => ({
        lineHeight: theme.lineHeights.compact,

        ...theme.fontVariants.bodySm,
        textTransform: 'uppercase',

        '> *': {
            display: 'inline-flex',
            verticalAlign: 'text-bottom',
        },
    }),
    ifProp({ colorVariant: 'grey' }, ({ theme }) => ({
        color: theme.colors.grey60,
    })),
);

export const StyledHeadlineButton = styled.button<{
    active: boolean;
    colorVariant?: 'black' | 'grey';
}>(
    ({ theme }) => ({
        // display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: theme.spaces[1],
        lineHeight: theme.lineHeights.compact,
        border: 'none',
        background: 'none',
        ...theme.fontVariants.bodySm,
        textTransform: 'uppercase',
        textAlign: 'left',
        padding: `${theme.spaces[2]} 0 ${theme.spaces[2]} 0`,

        '> figure': {
            display: 'inline-flex',
            opacity: 0,
            marginLeft: `${theme.spaces[1]}`,
        },

        '&:hover > figure': {
            // display: 'inline-flex',
            opacity: 1,
        },

        '> *': {
            display: 'inline-flex',
            verticalAlign: 'text-bottom',
        },
    }),
    ifProp({ colorVariant: 'grey' }, ({ theme }) => ({
        color: theme.colors.grey60,
    })),

    ifProp('active', {
        '> figure': {
            // display: 'inline-flex',
            opacity: 1,
        },
    }),
);

export const StyledColumnTooltip = styled.div(({ theme }) => ({
    marginLeft: theme.spaces[1],
}));
