import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { CompareProductsIds, CompareStore, Product } from '../../model';

export const useCompareStore = create(
    subscribeWithSelector<CompareStore>((set, get) => ({
        compareProducts:
            typeof window !== 'undefined'
                ? JSON.parse(sessionStorage.getItem('CompareProducts') ?? '[null, null, null]')
                : [null, null, null],
        compareProductsIds:
            typeof window !== 'undefined'
                ? JSON.parse(sessionStorage.getItem('CompareProductsIds') ?? '{}')
                : {},
        locale: typeof window !== 'undefined' ? sessionStorage.getItem('CompareLocale') ?? '' : '',
        showOverlay: false,
        setCompareProduct: (product: Product, locale?: string) => {
            const products = get().compareProducts;
            const productsIds = get().compareProductsIds;
            const currentLocale = get().locale;

            if (product.productId && productsIds[product.productId]) return;

            if (product.productId) productsIds[product.productId] = product.productId;

            for (let i = 0; i < products.length; i++) {
                if (products[i] === null) {
                    // To push the product on the empty position
                    products[i] = product;
                    break;
                }
            }

            set(() => ({
                compareProducts: products,
                compareProductsIds: productsIds,
                locale: locale || currentLocale,
            }));
            setSessionStorage(products, productsIds);
        },
        removeCompareProduct: (idx: number, productId?: string | null) => {
            const products = get().compareProducts;
            const productsIds = get().compareProductsIds;

            if (productId) productsIds[productId] = undefined;
            // products[idx] = null;
            products.splice(idx, 1);
            products.push(null);

            set(() => ({ compareProducts: products, compareProductsIds: productsIds }));
            setSessionStorage(products, productsIds);
        },
        clearCompareProduct: (locale?: string) => {
            set(() => ({ compareProducts: [null, null, null], compareProductsIds: {}, locale }));
            setSessionStorage([null, null, null], {}, locale);
        },
        handleCloseOverlay: () => {
            set(() => ({ showOverlay: false }));
        },
        handleOpenOverlay: () => {
            set(() => ({ showOverlay: true }));
        },
    })),
);

export const setSessionStorage = (
    compareProducts: (Product | null)[],
    compareProductsIds: CompareProductsIds,
    locale?: string,
) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem('CompareProducts', JSON.stringify(compareProducts));
        sessionStorage.setItem('CompareProductsIds', JSON.stringify(compareProductsIds));
        locale && sessionStorage.setItem('CompareLocale', locale);
    }
};

export const getCompareProductsLength = (compareProducts: (Product | null)[]) => {
    const products = compareProducts.filter((item) => item !== null);

    return products.length;
};
