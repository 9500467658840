import Close from '$icons/close.svg';
import CompareIcon from '$icons/compare.svg';
import NextLink from 'next/link';
import { Icon, Image, Text } from '~/shared/components';
import { useTranslation } from '~/shared/utils';
import { Product } from '../../../model';
import { StyledListItemText } from '../../ProductListItem/styled';
import { useCompareStore } from '../../hooks';
import { getProductImage } from '../../utils';
import {
    StyledCEmptyCards,
    StyledCompareProductButton,
    StyledCompareProductCards,
    StyledCompareProductClearButton,
    StyledCompareProductImage,
} from './Style';
import { getCurrentMarketPath } from '~/templates/pages/utils';

type CompareProductCards = {
    product: Product | null;
    idx: number;
};

export const CompareProductCards = ({ product, idx }: CompareProductCards) => {
    const { translate } = useTranslation();

    const { removeCompareProduct } = useCompareStore();

    if (!product) {
        return (
            <StyledCompareProductCards>
                <StyledCEmptyCards>
                    <Icon size="md">
                        <CompareIcon />
                    </Icon>
                    <Text variant={'captionSm'}>
                        {translate('Kompan.Commerce.CompareOverviewCardDescription')}
                    </Text>
                </StyledCEmptyCards>
            </StyledCompareProductCards>
        );
    }

    const { displayName, productId } = product;

    const productPageUrl = `/${getCurrentMarketPath()}/p`;

    return (
        <StyledCompareProductCards>
            <NextLink
                href={`${productPageUrl}/${product?.productId?.toLowerCase()}`}
                prefetch={false}
                legacyBehavior
            >
                <StyledCompareProductButton>
                    <StyledCompareProductImage>
                        <Image
                            sizes="120px"
                            quality="70"
                            objectFit="contain"
                            layout="fill"
                            src={getProductImage(product)}
                            alt={''}
                        />
                    </StyledCompareProductImage>
                    <div>
                        <StyledListItemText variant={'body'}>{displayName}</StyledListItemText>
                        <StyledListItemText variant="caption" colorVariant="grey">
                            {productId}
                        </StyledListItemText>
                    </div>
                </StyledCompareProductButton>
            </NextLink>
            <StyledCompareProductClearButton onClick={() => removeCompareProduct(idx, productId)}>
                <Icon size="md">
                    <Close />
                </Icon>
            </StyledCompareProductClearButton>
        </StyledCompareProductCards>
    );
};
