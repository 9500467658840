import styled from '@emotion/styled';
import { Button, StyledButtonContent } from '~/shared/components';
import { Property } from 'csstype';
import { Text } from '$shared/components';
import { ifProp } from 'styled-tools';
import { css } from '@emotion/react';
import { breakpoints } from '~/theme';

export const productListTemplateColumnsWithPrice = css({
    gridTemplateColumns: '150px 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 6em', //'100px 1fr 6em 6em 9em 6em 7em 11em',
});
export const productListTemplateColumns = css({
    gridTemplateColumns: '150px 1.5fr 1fr 1fr 1fr 1fr 1fr 6em', //'100px 1fr 6em 6em 9em 6em 7em 11em',
});

export const StyledProductListItem = styled.li<{ showPrice: string }>(
    ({ theme }) => ({
        display: 'grid',
        gap: `0 ${theme.spaces[2]}`,
        paddingBottom: theme.spaces[3],
        paddingTop: theme.spaces[3],
        borderBottom: `1px solid ${theme.colors.grey20}`,

        '&:hover': {
            backgroundColor: theme.colors.grey05,
        },
    }),
    ifProp({ showPrice: 'True' }, () => productListTemplateColumnsWithPrice),
    ifProp({ showPrice: 'False' }, () => productListTemplateColumns),
);

export const StyledListItemColumn = styled.div<{
    justifyContent?: Property.JustifyContent;
    alignItems?: Property.AlignItems;
    textAlign?: Property.TextAlign;
    flexDirection?: Property.FlexDirection;
    noPadding?: boolean;
}>(
    ({
        justifyContent = 'center',
        textAlign = 'center',
        flexDirection = 'row',
        alignItems = 'center',
        theme,
    }) => ({
        display: 'inline-flex',
        alignItems: alignItems,
        justifyContent: justifyContent,
        textAlign: textAlign,
        flexDirection: flexDirection,
        paddingTop: theme.spaces['6'],
        paddingBottom: theme.spaces['6'],
        // gap: theme.spaces['1'],
        zIndex: theme.zIndexes[3],
        [`${StyledButtonContent}`]: {
            whiteSpace: 'normal',
        },

        ['a']: {
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),

    ifProp({ noPadding: true }, () => ({
        padding: 0,
    })),
);

export const StyledImageContainer = styled.div(({ theme }) => ({
    position: 'relative',
    width: 100,
    height: 100,
    padding: `0 ${theme.spaces[6]} 0 ${theme.spaces[4]}`,
}));

export const StyledNoBreakText = styled.div({
    span: {
        whiteSpace: 'pre-wrap',
        display: 'inline-block',
    },
});

export const StyledListItemText = styled(Text)<{
    colorVariant?: 'black' | 'grey';
}>(
    ifProp({ colorVariant: 'grey' }, ({ theme }) => ({
        color: theme.colors.grey60,
    })),
    ifProp({ variant: 'body' }, ({ theme }) => ({
        [breakpoints.sm]: {
            ...theme.fontVariants.bodySm,
            lineHeight: theme.lineHeights.single,
        },
        [breakpoints.lg]: {
            ...theme.fontVariants.body,
            lineHeight: theme.lineHeights.single,
        },
    })),
    ifProp({ variant: 'caption' }, ({ theme }) => ({
        ...theme.fontVariants.bodySm,
    })),
);

export const StyledQuoteButton = styled(Button)(({ theme }) => ({
    padding: `${theme.spaces['3']} ${theme.spaces['4']}`,
    fontSize: theme.fontSizes.xs,
}));
