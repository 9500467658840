import {
    StructuredDataListProduct,
    StructuredDataOrganization,
    StructuredDataProduct,
} from '$shared/components';

export const getScriptInDOM = (
    doc: HTMLDocument,
    value: string,
    property: 'src' | 'id' = 'src',
): HTMLScriptElement | undefined => {
    const scripts = doc.getElementsByTagName('script');
    for (let i = scripts.length; i--; ) {
        if (scripts[i][property] === value) {
            return scripts[i];
        }
    }
    return undefined;
};

export const setJsonLd = (
    doc: HTMLDocument,
    jsonLd: StructuredDataOrganization | StructuredDataProduct | StructuredDataListProduct,
): void => {
    const id = `${jsonLd['@type']}Schema`;
    const scriptInDom = getScriptInDOM(doc, id, 'id');

    const scriptElement: HTMLScriptElement = scriptInDom || doc.createElement('script');
    scriptElement.innerHTML = JSON.stringify(jsonLd);

    if (!scriptInDom) {
        scriptElement.type = 'application/ld+json';
        scriptElement.id = id;
        doc.head.appendChild(scriptElement);
    }
};

export const removeJsonLd = (doc: HTMLDocument, type: string): void => {
    const id = `${type}Schema`;
    const scriptInDom = getScriptInDOM(doc, id, 'id');

    if (scriptInDom) {
        scriptInDom.parentNode?.removeChild(scriptInDom);
    }
};
